import React, { useState } from 'react'
import WelcomeHeader from '../WelcomeHeader'
// import VideoSection from './VideoSection'
import VideoCardSection from './VideoSection'
import FutureProofSection from './FutureProofSection'
import { Collapse } from 'antd'
// import Footer from '../Footer'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import Footer from '../../../components/Footer'
import { useTranslation } from 'react-i18next'

const AiPage = () => {

    const {t}                               = useTranslation();

    const [activeKey, setActiveKey] = useState([]);

    const handleIcon = (key) => {
        return activeKey?.includes(key) ? <CloseOutlined /> : <PlusOutlined />;
    };

    const onChange = (key) => {
        setActiveKey(key);
    };

    return (
        <div className="gx-welcome-pagev2 gx-ai-page">
            <WelcomeHeader />

            <main>

                <div className="gx-landing-container">
                    <FutureProofSection />
                    <VideoCardSection />

                </div>



                <section className="gx-faq-section gx-section-padding">
                    <div className="gx-landing-container">
                        <div className="gx-faq-wrapper">
                            <h2 className='gx-mb-3'>{t("FAQs")}</h2>
                            <Collapse
                                ghost
                                expandIconPosition="end"
                                bordered={false}
                                activeKey={activeKey}
                                onChange={onChange}
                                expandIcon={({ panelKey }) => handleIcon(panelKey)}
                                className="gx-faq-custom-collapse"
                            >






                                <Collapse.Panel header={t("How do I start integrating Al into my school?")} key="1">
                                    <p>{t("Book a demo with us Our team will guide you through")}</p>
                                </Collapse.Panel>
                                <Collapse.Panel header={t("Can I try Edutechs Al for free?")} key="2">
                                    <p>{t("Yes, we offer a demo or trial period")}</p>
                                </Collapse.Panel>
                                <Collapse.Panel header={t("How does the Al tutoring feature work?")} key="3">
                                    <p>{t("The Al tutor adapts to each student's unique")}</p>
                                </Collapse.Panel>
                                <Collapse.Panel header={t("Can Al-generated assessments replace traditional evaluations?")} key="4">
                                   <p>{t("Our Al-generated assessments are designed to be used alongside")}</p>
                                </Collapse.Panel>
                                <Collapse.Panel header={t("What types of learning materials can I generate?")} key="5">
                                    <p>{t("Our Al can create a variety of learning materials")}</p>
                                </Collapse.Panel>
                                <Collapse.Panel header={t("How does the conflict detection feature ensure student safety?")} key="6">
                                    <p>{t("Our Al-driven surveillance system monitors the campus in")}</p>
                                </Collapse.Panel>
                                <Collapse.Panel header={t("What tasks can the action model perform for educators?")} key="7">
                                    <p>{t("The action model automates various administrative")}</p>
                                </Collapse.Panel>
                                <Collapse.Panel header={t("What support is available after implementation?")} key="8">
                                    <p>{t("Yes You can revert back to the free plan whenever")}</p>
                                </Collapse.Panel>
                                <Collapse.Panel header={t("Can I upgrade later or in the future after I start using it?")} key="9">
                                    <p>{t("We provide ongoing support through our dedicated")}</p>
                                </Collapse.Panel>
                            </Collapse>
                        </div>
                    </div>
                </section>


            </main>

            {/* <Footer /> */}

            <Footer
                    heading="Give your classroom the upgrade it deserves"
                    buttonText="Start For Free"
                    footerTop={true}
                />
        </div>
    )
}

export default AiPage