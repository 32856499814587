import { useState, useEffect } from "react";

import { AutoComplete, Button, Tooltip, Menu, Dropdown} from "antd";
import { AlignCenterOutlined, FilterOutlined, SearchOutlined } from '@ant-design/icons';
import ExportButton from "../ExportButton";


//allCardsJSX -- this is array of all original JSX commonly set in useEffect of parent component (MUST BE COPY OF FULL CARD JSX AND ALWAYS HAVE ALL CARDS)
//can also be array of JSON but must pass in props type
//queryPropKey -- this is the prop which will contain the search query matches example: title or name
//updateAllCardsViewJSX -- this is the filtered setState function to UI view cards JSX

//make sure each card in this JSX has the queryPropKey you used
 

const SearchArrayJSX = ({ allCardsJSX, queryPropKey, updateAllCardsViewJSX, placeholder, width, showCustomFilterBool, customFilterInputs, onSelectCallback, showExportButtonBool, exportType, docFileName, headers, docExtraData, columnWidthStyle, filterMenuOnClick, extraColumns=[] }) => {

    const [value, setValue]                                         = useState('');         //keep a local state of active search input text value
    const [selectedDropdownFilter, setSelectedDropdownFilter]       = useState(undefined);  //keep a local state of active dropdown filter selected last

    const [options, setOptions]                                     = useState(allCardsJSX.map((t,index)=>{
        return {value: t.props[queryPropKey]}
    }));

    const [filterItems, setFilterItems]                             = useState([]);
    const [filterTitle, setFilterTitle]                             = useState('Filter');

    const [updateData, setUpdateData]                               = useState(allCardsJSX); //this is only used for export component

    //this useeffect is to set up default state of all cards
    //if an active filter is detected then that will be applied automatically
    useEffect(() => {
        let allCurrentCardsJSX      = [...allCardsJSX];
        //check to see if there is a dropdown filter/sort applied already
        if (selectedDropdownFilter){
            let thisSortFunction    = selectedDropdownFilter['sortFunction'];
            let thisFilterFunction  = selectedDropdownFilter['filterFunction'];

            //this means a sort function was passed in
            if (thisSortFunction){
                allCurrentCardsJSX.sort((a, b) => {
                    return thisSortFunction(a.props,b.props);
                });

            }
            //this means a filter function was passed in
            else if (thisFilterFunction){
                let newFilteredArray = [];

                for (let oneCompOf of allCurrentCardsJSX){
                    if (thisFilterFunction(oneCompOf.props) === true){
                        newFilteredArray.push(oneCompOf);
                    }
                }   

                allCurrentCardsJSX = [...newFilteredArray];
            }

        }

        //check to see if there is a search-text filter applied
        let query = value.trim().toLowerCase();
        if (query && query !== ''){
            //loop through both cards JSX and only keep those which match the string
            let finalJSX = [];
            let localCopy = [...allCurrentCardsJSX];
            localCopy.sort((a, b) => b.props.isSelected - a.props.isSelected);
            for (let oneCompOf of localCopy){
                if ((oneCompOf.props[queryPropKey]).toLowerCase().includes(query) || (oneCompOf.props['isSelected']) === true){
                    finalJSX.push(oneCompOf);
                }
            }   
            allCurrentCardsJSX = [...finalJSX];
        }

        setUpdateData(allCurrentCardsJSX);
        updateAllCardsViewJSX(allCurrentCardsJSX);
      }, [allCardsJSX, selectedDropdownFilter, value, queryPropKey, updateAllCardsViewJSX]);
    
    useEffect(() => {

        if (customFilterInputs && customFilterInputs.title){
            setFilterTitle(customFilterInputs.title);

            let outputFilterItems       = [];
            let allFiltersPassedInArray = customFilterInputs.filters;

            let index = 1;
            for (let oneFilter of allFiltersPassedInArray){

                let thisSortFunction    = oneFilter['sortFunction'];
                let thisFilterFunction  = oneFilter['filterFunction'];

                outputFilterItems.push(
                    <Menu.Item key={String(index)} type="primary">
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }} onClick={()=>{
                            //loop through both cards JSX and only keep those which match the string
                            let allCurrentCardsJSX      = [...allCardsJSX];
                            
                            //this means a sort function was passed in
                            if (thisSortFunction){
                                allCurrentCardsJSX.sort((a, b) => {
                                    return thisSortFunction(a.props,b.props);
                                });
        
                                updateAllCardsViewJSX(allCurrentCardsJSX);
                                setSelectedDropdownFilter(oneFilter);
                            }
                            //this means a filter function was passed in
                            else if (thisFilterFunction){
                                let newFilteredArray = [];

                                for (let oneCompOf of allCurrentCardsJSX){
                                    if (thisFilterFunction(oneCompOf.props) === true){
                                        newFilteredArray.push(oneCompOf);
                                    }
                                }   

                                updateAllCardsViewJSX(newFilteredArray);
                                setSelectedDropdownFilter(oneFilter);
                            }

                            if (filterMenuOnClick){
                                filterMenuOnClick(oneFilter['filterName']);
                            }
                        }}>
                            <span className="gx-ml-1 gx-mr-2">{oneFilter['filterName']}</span>
                            {oneFilter['icon'] ? oneFilter['icon'] : <AlignCenterOutlined />}
                        </div>
                    </Menu.Item>
                    
                )
                index = index + 1
            }

            setFilterItems(outputFilterItems);
            if (onSelectCallback){
                onSelectCallback([]);
            }
        }

    }, [customFilterInputs, showCustomFilterBool, allCardsJSX, updateAllCardsViewJSX, onSelectCallback, filterMenuOnClick])
    

    const onSearch = (searchText) => {
        let query = (searchText).trim().toLowerCase();
        //loop through both cards JSX and only keep those which match the string
        let finalOptionArr = [];

        let localCopy = [...allCardsJSX];
        localCopy.sort((a, b) => b.props.isSelected - a.props.isSelected);

        for (let oneCompOf of localCopy){
            if ((oneCompOf.props[queryPropKey]).toLowerCase().includes(query) || (oneCompOf.props['isSelected']) === true){
                finalOptionArr.push({
                    value: oneCompOf.props[queryPropKey]
                });
            }
        }   
        setOptions(
            !searchText ? [] : finalOptionArr,
        );
    };
  
    const onSelect = (value, option) => {
        if (onSelectCallback){
            onSelectCallback(value, option);
        }
    };
  
    const onChange = (data) => {

        let query = (data).trim().toLowerCase();

        //loop through both cards JSX and only keep those which match the string
        let finalJSX = [];

        let localCopy = [...allCardsJSX];
        localCopy.sort((a, b) => b.props.isSelected - a.props.isSelected);

        for (let oneCompOf of localCopy){
            if ((oneCompOf.props[queryPropKey]).toLowerCase().includes(query) || (oneCompOf.props['isSelected']) === true){
                finalJSX.push(oneCompOf);
            }
        }   
    
        updateAllCardsViewJSX(finalJSX);
        setValue(data);

        if (onSelectCallback){
            onSelectCallback(data);
        }
    };
  
    return (
        <div style={{display: 'flex', justifyContent: "flex-start", alignItems: 'center', flexDirection: 'row'}}>
            <AutoComplete
                value         =   {value}
                options       =   {options}
                style         =   {{width: width ? width : 200}}
                onSelect      =   {onSelect}
                onSearch      =   {onSearch}
                onChange      =   {onChange}
                prefix        =   {<SearchOutlined />}
                placeholder   =   {placeholder ? placeholder : 'Search'}
            />

            {showCustomFilterBool === true ? 

                <div>
                    <Dropdown
                        overlay={<Menu onClick={(x)=>{

                        }}>
                            {filterItems}
                        </Menu>}
                    >
                        <Tooltip title={filterTitle}>
                            <Button className="gx-mx-0 gx-ml-2" style={{marginBottom: 0, height: '32px'}} type="dashed" shape="square" icon={<FilterOutlined/>}></Button>
                        </Tooltip>
                    </Dropdown>
                </div>

                :
                <></>
            }

            {showExportButtonBool === true ?

                <ExportButton
                    exportType      =   {exportType}
                    docData         =   {updateData}
                    docFileName     =   {docFileName}
                    headers         =   {headers}
                    extraColumns    =   {extraColumns}
                    icon            =   {true}
                    docExtraData    =   {docExtraData}
                    columnWidthStyle=   {columnWidthStyle}

                />

                :
                <></>
            }
        </div>
    );
    
};
SearchArrayJSX.defaultProps = {
    showExportButtonBool : false,
    exportType           : [],
    docFileName          : "",
    headers              : [],
    docExtraData         : [],
    columnWidthStyle     : {},
    filterMenuOnClick    : ()=>{}
};
export default SearchArrayJSX;


