import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Image, Tag, Skeleton, Divider, Typography } from "antd";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";



import firebase from "firebase";

import moment from "moment";
import WelcomeHeader from "../WelcomeHeader";
import { updateWindowWidth } from "../../../appRedux/actions";
import { calculateReadingTime, decodeString } from "../../../util/misc";
import SearchArrayJSX from "../../../components/SearchArrayJSX";
import Footer from "../../../components/Footer";
import { useTranslation } from "react-i18next";
// import Footer from "../Footer";


const database = firebase.database();

const AllNewsPage = () => {

    const dispatch = useDispatch();
    const [allNewsJSX, setAllNewsJSX] = useState([]);
    const history = useHistory();
    const [filteredAllNewsArray, setFilteredAllNewsArray] = useState([]);
    const [loadingNewsSection, setLoadingNewsSection] = useState(false);
    const [allNewsArray, setAllNewsArray] = useState([]);
    const [lastKey, setLastKey] = useState(null);
    const [isEndOfData, setIsEndOfData] = useState(false);

    const {t}                                           = useTranslation();

    useEffect(() => {
        window.addEventListener("resize", () => {
            dispatch(updateWindowWidth(window.innerWidth));
        });
    }, [dispatch]);

    useEffect(() => {
        // Include the Crisp code here, without the <script></script> tags
        window.$crisp = [];
        window.CRISP_WEBSITE_ID = "b81a179e-9090-4acb-a36b-f38f6f37abe8";

        (function () {
            var d = document;
            var s = d.createElement("script");

            s.src = "https://client.crisp.chat/l.js";
            s.async = 1;
            d.getElementsByTagName("head")[0].appendChild(s);
        })();
    }, []);


    useEffect(() => {
        setLoadingNewsSection(true);

        let newsRef = database.ref('ConfigLandingPage/NewsSection/').orderByChild('date').limitToLast(10);
        let lastFetchedKey = null;
        const handleData = snapshot => {
            const data = [];
            const allNews = snapshot.val();

            for (let key in allNews) {
                lastFetchedKey = allNews[key].date;
                const oneNews = allNews[key];
                let avgTimeToread = calculateReadingTime(decodeString(oneNews?.description), 100); // 2nd parameter is how many words can read per minute
                data.push({
                    key: key,
                    ...oneNews,
                    avgReadTime: avgTimeToread,
                    props: {
                        'search-key': `${decodeString(oneNews.title)} - ${decodeString(oneNews.author)} - ${decodeString(oneNews.type)} - ${moment(oneNews.date).format('LL')}`,
                    },
                });
            }
            //for descending order 
            data.reverse();
            setAllNewsArray(data);
            setFilteredAllNewsArray(data);
            setLastKey(lastFetchedKey);
            setLoadingNewsSection(false);
            setIsEndOfData(false);
        };

        newsRef.on('value', handleData);

        return () => {
            newsRef.off('value', handleData);
        };
    }, []);

    const fetchMoreData = async () => {
        if (loadingNewsSection || isEndOfData) return;
        setLoadingNewsSection(true);

        const newsRef = database.ref('ConfigLandingPage/NewsSection').orderByChild('date').endAt(lastKey).limitToLast(20);
        newsRef.once('value', snapshot => {
            const data = [];
            let newLastKey = lastKey;

            snapshot.forEach(childSnapshot => {

                const oneNews = childSnapshot.val();
                const key = childSnapshot.key;

                if (!allNewsArray.some(news => news.key === key)) { // not to add duplicate data
                    const avgTimeToRead = calculateReadingTime(decodeString(oneNews?.description), 100);

                    data.push({
                        key: childSnapshot.key,
                        ...oneNews,
                        avgReadTime: avgTimeToRead,
                        props: {
                            'search-key': `${decodeString(oneNews.title)} - ${decodeString(oneNews.author)} - ${decodeString(oneNews.type)} - ${moment(oneNews.date).format('LL')}`,
                        },
                    });
                    newLastKey = oneNews?.date;
                }
            });

            data.reverse();

            if (data.length === 0) {
                setIsEndOfData(true);
            } else {
                setLastKey(newLastKey);
                setAllNewsArray(prevNews => [...prevNews, ...data]);
                setFilteredAllNewsArray(prevNews => [...prevNews, ...data]);
            }

            setLoadingNewsSection(false);
        });
    };

    return (
        <div className="gx-welcome-pagev2 gx-all-news-page">
            <WelcomeHeader />

            <main>
                <section className="gx-all-news-section gx-section-padding">
                    <div className="gx-landing-container">
                        <div className="gx-news-header">
                            <h2>{t("Latest News & Updates")}</h2>
                            <SearchArrayJSX
                                allCardsJSX={allNewsArray}
                                updateAllCardsViewJSX={setFilteredAllNewsArray}
                                queryPropKey={"search-key"}
                                placeholder={t("Search By News Title , Author, Type or Date")}
                                width={320}
                                onSelectCallback={(selected) => console.log(selected)}
                                showCustomFilterBool={false}
                            />
                        </div>

                        <div className="gx-all-news-wrapper gx-py-4">
                            <div style={{ width: "100%", borderRadius: '12px' }}>
                                <Skeleton loading={loadingNewsSection}>

                                    <Row>
                                        {filteredAllNewsArray.map((news) => (
                                            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                                <div style={{cursor: 'pointer'}} key={news.id} className="gx-mb-5"  onClick={() => window.open(`${window.location.origin}/OpenNews?key=${news?.key}`, '_blank')}>
                                                    <div className="gx-news-card">
                                                        <img src={news?.image} alt={news.title} className="gx-news-image" />
                                                        <div className="gx-news-contents">
                                                            <span className="gx-news-label">{news.label ? news.label : 'Announcement'}</span>


                                                            <Typography.Paragraph style={{ lineHeight: '1.2' }} className="gx-news-title" ellipsis={{ rows: 2 }}>
                                                                {decodeString(news?.title)}
                                                            </Typography.Paragraph>
                                                            <Typography.Paragraph style={{ lineHeight: '1.2' }} className="gx-news-description" ellipsis={{ rows: 2 }} >
                                                                <div
                                                                    dangerouslySetInnerHTML={{ __html: decodeString(news?.description) }}
                                                                />
                                                            </Typography.Paragraph >
                                                            <div className="gx-news-footer">
                                                                <span>{moment(news?.date).format("ll")}</span>
                                                                <span>{news?.avgReadTime}m Read</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>


                                </Skeleton>
                                {!isEndOfData && !loadingNewsSection && (
                                    <div style={{ textAlign: 'center', marginTop: '20px', paddingBottom: '50px' }}>
                                        <Button onClick={fetchMoreData} loading={loadingNewsSection}>{t("Load More")}</Button>
                                    </div>
                                )}
                                {isEndOfData && (
                                    <div style={{ textAlign: 'center', marginTop: '20px', paddingBottom: '50px' }}>
                                        <p>{t("No more data available")}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            {/* <Footer /> */}
            <Footer
                    heading="Give your classroom the upgrade it deserves"
                    buttonText="Start For Free"
                    footerTop={false}
                />
        </div>
    );
};

export default AllNewsPage;
