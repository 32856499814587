import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { switchLanguage } from '../../appRedux/actions';
import languageData from '../../containers/Topbar/languageData';
import { useTranslation } from 'react-i18next';

const Footer = ({ heading, buttonText, footerTop }) => {
    const history = useHistory();
    const themeType = useSelector(({ settings }) => settings.themeType);

    const { locale } = useSelector(({ settings }) => settings);

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    


    const currentLanguage = (values) => {
        const language = languageData.find((language) => language.locale === values);
        if (language) {
            dispatch(switchLanguage(language));

            // Update local storage language
            localStorage.setItem("lng", language.locale);

            // Update i18n language
            i18n.changeLanguage(language.locale);
        }
    };

    useEffect(() => {
        const localLang = localStorage.getItem("lng");
        if(localLang){
            dispatch(switchLanguage(languageData.find((language) => language.locale === localLang)));
        }
    }, [dispatch])

    return (
        <footer className="gx-footer">
            <div className="gx-landing-container gx-footer-content-wrapper">
                {
                    footerTop &&
                    <div className="gx-footer-top gx-case-study-page-footer-top gx-case-study-footer-top">
                        <h2 className="gx-case-study-footer-heading">
                            {t(heading) || t("Give your classroom the upgrade it deserves")}
                        </h2>
                        <button onClick={() => history.push('/signup')} className="gx-case-study-footer-cta">{t(buttonText) || t("Start For Free")}</button>
                    </div>
                }
                <div className={`gx-footer-bottom ${footerTop && "gx-case-study-page-footer-bottom"}`}>
                    <div className="gx-footer-left">
                        <img src={`/assets/images/welcomev2/logo-white.png`} alt="Edutechs Logo" className="gx-footer-logo" />
                        <Select
                            suffixIcon={<span className="gx-custom-arrow">▼</span>}
                            className="gx-language-selector"
                            defaultValue={locale.locale}
                            onChange={currentLanguage}
                        >
                            <Select.Option value="en">English</Select.Option>
                            <Select.Option value="bn">{t("Bangla")}</Select.Option>

                        </Select>
                    </div>

                    <div className="gx-footer-links">
                        <div className="gx-footer-column">
                            <h4 >{t("Company")}</h4>
                            <ul>
                                <li><Link to="/blog">{t("News / Blog")}</Link></li>
                                <li><Link to="/contact">{t("Contact Us")}</Link></li>
                                <li><Link to="/careers">{t("Careers")}</Link></li>
                                <li><Link to="/pricing">{t("Pricing")}</Link></li>
                            </ul>
                        </div>

                        <div className="gx-footer-column">
                            <h4>{t("Products")}</h4>
                            <ul>
                                <li><Link to="/welcome">{t("Edutechs LMS")}</Link></li>
                                <li>
                                    <Link to="/ai">{t("Edutechs AI")} <span className="gx-new">{t("NEW")}</span></Link>
                                </li>
                                <li><Link to="/sentinel">{t("Sentinel – Student Safety")}</Link></li>
                                <li><Link to="/ex/sms">{t("Bulk SMS Gateway")}</Link></li>
                            </ul>
                        </div>

                        <div className="gx-footer-column">
                            <h4>{t("Use Cases")}</h4>
                            <ul>
                                <li><Link to="/welcome">{t("Education Management")}</Link></li>
                                <li><Link to="/sentinel">{t("Attendance Automation")}</Link></li>
                            </ul>
                        </div>

                        <div className="gx-footer-column">
                            <h4>{t("Community")}</h4>
                            <ul>
                                <li><a href="https://www.facebook.com/edutechs.org" target="_blank" rel="noopener noreferrer">{t("Facebook")}</a></li>
                                <li><a href="https://www.linkedin.com/company/edutechbd/" target="_blank" rel="noopener noreferrer">{t("LinkedIn")}</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="gx-footer-copyright">
                    <span>© {t("Edutechs")}</span>
                    <div>
                        <Link to="/Terms">{t("Terms of Service")}</Link>
                        <Link to="/Privacy">{t("Privacy Policy")}</Link>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;