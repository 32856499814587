import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { calculateReadingTime, decodeString } from '../../../util/misc';


import firebase from "firebase";
import moment from 'moment';
import { Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
const database = firebase.database();

const newsData = [
    {
        id: 1,
        image: "/assets/images/welcomev2/news1.png",
        label: 'Announcement',
        title: 'EduPay: Gateway to Hassle-Free Payments for Institutions',
        description: 'We are excited to announce the launch of our new online payment feature, EduPay...',
        date: 'Aug 4, 2022',
        readTime: '8m read',
    },
    {
        id: 2,
        image: "/assets/images/welcomev2/news2.png",
        label: 'Announcement',
        title: 'Streamline Student Enrollments with Our New Online Admission Add-on',
        description: 'Edutechs team is super excited to tell you about the newest edition to our...',
        date: 'Jul 29, 2024',
        readTime: '5m read',
    },
    {
        id: 3,
        image: "/assets/images/welcomev2/news3.png",
        label: 'Announcement',
        title: 'Global Student Tagging',
        description: 'Presenting new global search feature and student tagging. Now you can easily...',
        date: 'Jul 1, 2024',
        readTime: '5m read',
    },
];



const BlogSection = () => {

    const {t}                                               = useTranslation();

    const [loadingNewsSection, setLoadingNewsSection] = useState(false);
    const [allNewsArray, setAllNewsArray] = useState([]);


    useEffect(() => {
        setLoadingNewsSection(true);

        let newsRef = database.ref('ConfigLandingPage/NewsSection/').orderByChild('date').limitToLast(10);
        const handleData = snapshot => {
            const data = [];
            const allNews = snapshot.val();

            for (let key in allNews) {
                const oneNews = allNews[key];
                let avgTimeToread = calculateReadingTime(decodeString(oneNews?.description), 100); // 2nd parameter is how many words can read per minute
                data.push({
                    key: key,
                    ...oneNews,
                    avgReadTime: avgTimeToread,
                    props: {
                        'search-key': `${decodeString(oneNews.title)} - ${decodeString(oneNews.author)} - ${decodeString(oneNews.type)} - ${moment(oneNews.date).format('LL')}`,
                    },
                });
            }
            //for descending order 
            data.reverse();
            setAllNewsArray(data);
            setLoadingNewsSection(false);

        };

        newsRef.on('value', handleData);

        return () => {
            newsRef.off('value', handleData);
        };
    }, []);
    return (
        <div className="gx-news-section gx-section-padding">
            <div className="gx-landing-container">
                <div className="gx-news-header">
                    <h2>{t("News / Blog")}</h2>
                    <Link to="/blog" className="gx-news-all">{t("All Blog")}</Link>
                </div>
                <Row>
                    {allNewsArray.slice(0,3).map((news) => (
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <div key={news.id} className="gx-mb-5" style={{cursor: 'pointer'}} onClick={() => window.open(`${window.location.origin}/OpenNews?key=${news?.key}`, '_blank')}>
                                <div className="gx-news-card">
                                    <img src={news?.image} alt={news.title} className="gx-news-image" />
                                    <div className="gx-news-contents">
                                        <span className="gx-news-label">{news.label ? news.label : t('Announcement')}</span>


                                        <Typography.Paragraph style={{ lineHeight: '1.2' }} className="gx-news-title" ellipsis={{ rows: 2 }}>
                                            {decodeString(news?.title)}
                                        </Typography.Paragraph>
                                        <Typography.Paragraph style={{ lineHeight: '1.2' }} className="gx-news-description" ellipsis={{ rows: 2 }} >
                                            <div
                                                dangerouslySetInnerHTML={{ __html: decodeString(news?.description) }}
                                            />
                                        </Typography.Paragraph >
                                        <div className="gx-news-footer">
                                            <span>{moment(news?.date).format("ll")}</span>
                                            <span>{news?.avgReadTime}m Read</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>

            </div>
        </div>
    );
};

export default BlogSection;
