import { useEffect, useRef } from 'react';
import { Card, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';

const cardData = [
    {
        title: "Give each student a unique tutor tailored to their learning style",
        description: "AI-tuned tutoring helps each student find their optimal learning path.",
        leftVideoSrc: "/assets/videos/welcomev2/video1.mp4",
        rightVideoSrc: "/assets/videos/welcomev2/video1.mp4",
    },
    {
        title: "Automatically create assessments & evaluate students",
        description: "AI-generated tailored assessments target weak areas, offer instant feedback, and lighten teachers' workload with personalized evaluations.",
        leftVideoSrc: "/assets/videos/welcomev2/video2.mp4",
        rightVideoSrc: "/assets/videos/welcomev2/video2.mp4",
    },
    {
        title: "Generate learning materials on the fly",
        description: "Instantly crafted learning resources adapt to student needs in real-time.",
        leftVideoSrc: "/assets/videos/welcomev2/video3.mp4",
        rightVideoSrc: "/assets/videos/welcomev2/video3.mp4",
    },
    {
        title: "Ensure safety for all with real-time conflict detection",
        description: "AI-driven solutions detect conflicts in real-time, ensuring safety for everyone in the workplace or learning environment.",
        leftVideoSrc: "/assets/videos/welcomev2/video4.mp4",
        rightVideoSrc: "/assets/videos/welcomev2/video4.mp4",
    },
    {
        title: "Let action model do your work on your behalf",
        description: "AI-powered automation models take care of repetitive tasks, allowing you to focus on the creative aspects of your work.",
        leftVideoSrc: "/assets/videos/welcomev2/video5.mp4",
        rightVideoSrc: "/assets/videos/welcomev2/video5.mp4",
    }
];


const VideoCardSection = () => {
    const leftVideoRefs = useRef([]);
    const rightVideoRefs = useRef([]);
    const cardRefs = useRef([]);
    const {t}                       = useTranslation();

    useEffect(() => {
        const handleVisibility = (entries) => {
            entries.forEach(entry => {
                const index = entry.target.getAttribute('data-index');
                if (leftVideoRefs.current[index] && rightVideoRefs.current[index]) {
                    if (entry.isIntersecting) {
                        leftVideoRefs.current[index].play();
                        rightVideoRefs.current[index].play();
                    } else {
                        leftVideoRefs.current[index].pause();
                        rightVideoRefs.current[index].pause();
                    }
                }
            });
        };

        const observer = new IntersectionObserver(handleVisibility, { threshold: 0.6 });

        cardRefs.current.forEach((card) => {
            if (card) {
                observer.observe(card);
            }
        });

        return () => {
            cardRefs.current.forEach((card) => {
                if (card) {
                    observer.unobserve(card);
                }
            });
        };
    }, []);

    return (
        <>
            {cardData.map((card, index) => (
                <div
                    key={index}
                    className={`gx-cards-wrapper gx-cards-wrapper${index+1}`}
                    style={{ paddingTop: index === 0 ? '40px' : '100px', paddingBottom: '100px' }}
                    ref={(el) => (cardRefs.current[index] = el)}
                    data-index={index}
                >
                    <Card
                        id="gx-card-section"
                        className="gx-card-section gx-mb-3"
                        bordered={false}
                        style={{
                            overflow: 'hidden',
                            padding: 0,
                            margin: '20px 0',
                        }}
                    >
                        <Row key={index}>

                            {
                                index === 2 ?
                                    <>

                                        <Col className="gx-video-section-column" xs={24} md={11} style={{ position: 'relative', padding: 0 }}>


                                            <video
                                                ref={(el) => (rightVideoRefs.current[index] = el)}
                                                muted
                                                loop
                                                playsInline
                                                style={{
                                                    position: 'relative',
                                                    top: 0,
                                                    left: 0,
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover',
                                                    border: 'none',
                                                    // background: 'rgba(211, 222, 224, 10)',
                                                    // backdropFilter: 'blur(10px)',
                                                    // WebkitBackdropFilter: 'blur(10px)',
                                                }}
                                            >
                                                <source src={card.rightVideoSrc} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>

                                            <div className="gx-overlay-wrapper gx-overlay-wrap"
                                                style={{
                                                    position: 'absolute',
                                                    width: '100%',
                                                    height: '100%',
                                                    top: '0',
                                                    left: '0',
                                                    color: '#000',
                                                    zIndex: 2,
                                                    // background: 'rgba(211, 222, 224, 10)',
                                                    // backdropFilter: 'blur(10px)',
                                                    // WebkitBackdropFilter: 'blur(10px)',
                                                }}>
                                                <div className="gx-overlay-box">
                                                   
                                                        <p className="gx-mb-0">{t("Create a cool animation showcasing how genetic engineering works")}</p>
                                                    
                                                </div>
                                            </div>
                                        </Col>

                                        <Col className="gx-video-section-column" xs={24} md={13} style={{ position: 'relative', padding: 0 }}>
                                            <video
                                                ref={(el) => (leftVideoRefs.current[index] = el)}
                                                muted
                                                loop
                                                playsInline
                                                style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover',
                                                }}
                                            >
                                                <source src={card.leftVideoSrc} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>

                                            <div className="gx-overlay-wrapper"
                                                style={{
                                                    position: 'absolute',
                                                    width: '100%',
                                                    height: '100%',
                                                    top: '0',
                                                    left: '0',
                                                    color: '#000',
                                                    zIndex: 2,
                                                    background: 'rgba(211, 222, 224, 10)',
                                                    backdropFilter: 'blur(10px)',
                                                    WebkitBackdropFilter: 'blur(10px)',
                                                }}>
                                                <div className="gx-overlay-text">
                                                    <h1>{t(card.title)}</h1>
                                                    <p>{t(card.description.length > 20 ? card.description.slice(0, 20) : card.description)}</p>
                                                </div>
                                            </div>
                                        </Col>


                                    </>
                                    :
                                    <>

                                        {
                                            index % 2 !== 0 ?

                                                <>

                                                    <Col className="gx-video-section-column" xs={24} md={13} style={{ position: 'relative', padding: 0 }}>
                                                        <video
                                                            ref={(el) => (leftVideoRefs.current[index] = el)}
                                                            muted
                                                            loop
                                                            playsInline
                                                            style={{
                                                                position: 'absolute',
                                                                top: 0,
                                                                left: 0,
                                                                width: '100%',
                                                                height: '100%',
                                                                objectFit: 'cover',
                                                            }}
                                                        >
                                                            <source src={card.leftVideoSrc} type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>

                                                        <div className="gx-overlay-wrapper"
                                                            style={{
                                                                position: 'absolute',
                                                                width: '100%',
                                                                height: '100%',
                                                                top: '0',
                                                                left: '0',
                                                                color: '#000',
                                                                zIndex: 2,
                                                                background: 'rgba(255, 255, 255, 0.6)',
                                                                backdropFilter: 'blur(10px)',
                                                                WebkitBackdropFilter: 'blur(10px)',
                                                            }}>
                                                            <div className="gx-overlay-text gx-text-right">
                                                                <h1>{t(card.title)}</h1>
                                                                <p>{t(card.description.length > 20 ? card.description.slice(0, 20) : card.description)}</p>
                                                            </div>
                                                        </div>
                                                    </Col>

                                                    <Col className="gx-video-section-column" xs={24} md={11} style={{ position: 'relative', padding: 0 }}>


                                                        <video
                                                            ref={(el) => (rightVideoRefs.current[index] = el)}
                                                            muted
                                                            loop
                                                            playsInline
                                                            style={{
                                                                position: 'absolute',
                                                                top: 0,
                                                                left: 0,
                                                                width: '100%',
                                                                height: '100%',
                                                                objectFit: 'cover',
                                                                border: 'none',
                                                            }}
                                                        >
                                                            <source src={card.rightVideoSrc} type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    </Col>



                                                </>

                                                :

                                                <>




                                                    <Col className="gx-video-section-column" xs={24} md={11} style={{ position: 'relative', padding: 0 }}>
                                                        <video
                                                            ref={(el) => (rightVideoRefs.current[index] = el)}
                                                            muted
                                                            loop
                                                            playsInline
                                                            style={{
                                                                position: 'absolute',
                                                                top: 0,
                                                                left: 0,
                                                                width: '100%',
                                                                height: '100%',
                                                                objectFit: 'cover',
                                                                border: 'none',
                                                            }}
                                                        >
                                                            <source src={card.rightVideoSrc} type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    </Col>

                                                    <Col className="gx-video-section-column" xs={24} md={13} style={{ position: 'relative', padding: 0 }}>
                                                        <video
                                                            ref={(el) => (leftVideoRefs.current[index] = el)}
                                                            muted
                                                            loop
                                                            playsInline
                                                            style={{
                                                                position: 'absolute',
                                                                top: 0,
                                                                left: 0,
                                                                width: '100%',
                                                                height: '100%',
                                                                objectFit: 'cover',
                                                            }}
                                                        >
                                                            <source src={card.leftVideoSrc} type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>

                                                        <div className="gx-overlay-wrapper"
                                                            style={{
                                                                position: 'absolute',
                                                                width: '100%',
                                                                height: '100%',
                                                                top: '0',
                                                                left: '0',
                                                                color: '#000',
                                                                zIndex: 2,
                                                                background: 'rgba(255, 255, 255, 0.6)',
                                                                backdropFilter: 'blur(10px)',
                                                                WebkitBackdropFilter: 'blur(10px)',
                                                            }}>
                                                            <div className="gx-overlay-text">
                                                                <h1>{t(card.title)}</h1>
                                                                <p>{t(card.description.length > 20 ? card.description.slice(0, 20) : card.description)}</p>
                                                            </div>
                                                        </div>
                                                    </Col>



                                                </>
                                        }

                                    </>
                            }


                        </Row>
                    </Card>
                </div>
            ))}
        </>
    );
};

export default VideoCardSection;
