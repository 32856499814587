import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const CaseStudiesSection = () => {
    const {t}                                   = useTranslation();
    return (
        <div className="gx-case-studies-section"  style={{paddingBottom: "280px"}}>
            <div className="gx-landing-container">
                <div className="gx-news-header">
                    <h2>{t("Case Studies")}</h2>
                </div>
                <div className="gx-case-study-card">
                    <div className="gx-case-image-container">
                        <img
                            src= "/assets/images/welcomev2/case-logo.png"
                            alt="Edbase Logo"
                            className="gx-case-main-logo"
                        />
                    </div>
                    <div className="gx-case-study-content">
                        <h3>
                        {t("Modernizing & Scaling The Operation of Edbase")}
                        </h3>
                        <p>
                        {t("Edbase, a leading after-school education institute with")}
                        </p>
                        <Link to="/casestudy" className="gx-view-button">
                            {t("View Detailed Case Study")}
                        </Link>
                    </div>

                </div>
            </div>

        </div>
    );
};

export default CaseStudiesSection;
